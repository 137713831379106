const config = {
    pollingTime: 2000,
    client: {
        url: 'https://bandbmb.com',
    },
    api: {
        url: 'https://api.bandbmb.com',
    },
    ws: {
        url: 'wss://ws.bandbmb.com',
    },
};

export default config;

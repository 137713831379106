import React from 'react';
import _ from 'lodash';
import { Link, Navigate } from 'react-router-dom';
import Client from '../../../lib/client';
import { CustomRouteProps } from '../../../lib/components/route';
import logoIcon from '../images/logo-icon.svg';
import forgotPass from '../images/element/forgot-pass.svg';

type State = {
  loading: boolean;
  username?: string;
  publicCode?: string;
  secretCode: string;
  message?: {
    type: 'success' | 'danger';
    text: string;
  }
}

export default class SignIn extends React.Component<CustomRouteProps, State> {
  constructor(props: CustomRouteProps) {
    super(props);

    this.state = {
      loading: false,
      username: _.get(props.urlValues, 'username')?.toString() || undefined,
      publicCode: _.get(props.urlValues, 'publicCode')?.toString() || undefined,
      secretCode: _.get(props.urlValues, 'secretCode')?.toString() || '',
    };
  }

  onChangeText = (field: 'secretCode', event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    event.preventDefault();
    this.setState({ secretCode: event.target.value });
  }

  onSubmit = async (event: React.ChangeEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (this.validateForm()) {
      this.setState({ loading: true });

      try {
        const client = new Client();
        await client.api.auth.authenticateWithVerificationCode(
          this.state.publicCode || '',
          this.state.secretCode,
        );
      } catch (error) {
        console.log(JSON.stringify(error));
        this.setState({
          loading: false,
          secretCode: '',
          message: {
            type: 'danger',
            text: _.get(error, 'message', 'Unable to sign in. Please try again!'),
          }
        });
      }
    }
  }

  onResendCode = async () => {
    if (this.validateForm()) {
      this.setState({ loading: true });

      try {
        const client = new Client();
        const publicCode = await client.api.auth.requestVerificationCode(
          this.state.username || '',
        );

        this.setState({
          publicCode,
          loading: false,
          message: undefined
        });
      } catch (error) {
        this.setState({
          loading: false,
          publicCode: undefined,
          message: {
            type: 'danger',
            text: _.get(error, 'message', 'Unable to sign in. Please try again!'),
          }
        });
      }
    }
  }

  validateForm() {
    return true;
  }

  render() {
    if (!this.state.publicCode || !this.state.username) {
      return <Navigate to="/" />
    }
    return (
      <main>
        <section className="vh-xxl-100">
          <div className="container h-100 d-flex px-0 px-sm-4">
            <div className="row justify-content-center align-items-center m-auto">
              <div className="col-12">
                <div className="bg-mode shadow rounded-3 overflow-hidden">
                  <div className="row g-0">

                    <div className="col-lg-6 d-md-flex align-items-center order-2 order-lg-1">
                      <div className="p-3 p-lg-5">
                        <img src={forgotPass} alt="forgotPass" />
                      </div>
                      <div className="vr opacity-1 d-none d-lg-block"></div>
                    </div>

                    <div className="col-lg-6 order-1">
                      <div className="p-4 p-sm-7">

                        <a href="index.html">
                          <img className="mb-4 h-50px" src={logoIcon} alt="logo" />
                        </a>

                        <h1 className="mb-2 h3">Verification Code</h1>
                        <p className="mb-sm-0">Check your email and enter the verification code</p>

                        <form autoComplete="off" onSubmit={this.onSubmit} className="mt-sm-4 text-start">

                          {
                            this.state.message ? (
                              <div className={`alert alert-${this.state.message.type}`} role="alert">
                                {this.state.message.text}
                              </div>
                            ) : null
                          }

                          <div className="mb-3">
                            <label className="form-label">Username</label>
                            <p className="mb-0 fw-semibold ">{this.state.username}</p>
                          </div>

                          <div className="mb-3">
                            <label className="form-label">Verification Code</label>
                            <input
                              onChange={this.onChangeText.bind(this, 'secretCode')}
                              value={this.state.secretCode}
                              type="tel"
                              className="form-control"
                              required={true}
                              autoFocus={true}
                              autoComplete="off"
                            />
                            <p className="mb-0 text-muted"><small>Code will expire in 60s</small></p>
                          </div>

                          <div className="mb-3 text-center">
                            <p>Or maybe <Link className="text-decoration-none" to="/sign-in">Sign in!</Link></p>
                          </div>

                          <div className="row">
                            <div className="col">
                              <button type="submit" className="w-100 btn btn-primary">Verify</button>
                            </div>
                            <div className="col">
                              <button type="submit" className="w-100 btn btn-secondary" onClick={this.onResendCode}>Resend Code</button>
                            </div>
                          </div>

                          <div className="text-primary-hover text-body mt-3 text-center">
                            Copyrights ©2024 BAND BMB by <a href="https://www.webestica.com/" className="text-body">CAVILLO </a>.
                          </div>

                        </form>
                      </div>
                    </div>

                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    );
  }
}
